import React from "react";
import Body from "../../components/Monkeypoxbody/body";

export default function Monkeypox() {

  return (
    <div>
      <Body/>
    </div>
  );
  }