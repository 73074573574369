import React from "react";
import Virus from "../../components/virusmainsection/Virus";

export default function Mainsectiontwo() {

  return (
    <div>
      <Virus/>
    </div>
  );

  }