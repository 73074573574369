import React from "react";
import Article from "../../components/articleabout/Article";

export default function About() {

  return (
    <Article />
  );
}

