export function WHO({mutationEntry}){

    const {who } = mutationEntry
  
    return (
      <div>
        <p className ="">{who}</p>
      </div>
      
    )
  }
  