import React from "react";
import Body from "../../components/Covidbody/body";

export default function Covid() {

  return (
    <div>
      <Body/>
    </div>
  );
  }